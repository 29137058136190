<template>
  <Layout>
    <PageHeader :items="items" :title="title" />

    <div
      v-if="
        login_type == 3 || this.$storageData.profile.empTypeID == 5
      "
      class="col-md-2"
      style="position: absolute;right: 47%;top: 75px;"
    >
      <label class="form-label" style="font-size: 12px;">Select Country </label>
      <multiselect
        v-model="country"
        :options="countryArr"
        :show-labels="false"
        label="country"
        track-by="country"
        @input="getCorporateCities()"
      ></multiselect>
    </div>
    <div
      v-if="
        login_type == 3 || this.$storageData.profile.empTypeID == 5
      "
      class="col-md-2"
      style="position: absolute;right: 30.5%;top: 75px;"
    >
      <label style="font-size: 12px;">Select City </label>
      <multiselect
        v-model="city"
        :options="cityArr"
        :show-labels="false"
        label="city"
        track-by="city"
        @input="getCorporateBranchByCorpID()"
      ></multiselect>
    </div>
    <div class="row mt-3">
      <div
        v-if="
          this.$storageData.profile.empTypeID == 1 || this.$storageData.profile.empTypeID == 9
        "
        class="col-md-2"
        style="width: 20%"
      >
        <label class="form-label" style="font-size: 14px"
          >Select Corporate
        </label>
        <multiselect
          v-model="corporateID"
          :options="corporateArr"
          :show-labels="false"
          label="corporateName"
          track-by="corporateID"
          @input="getCorporateBranchByCorpID()"
        ></multiselect>
      </div>

      <div class="col-md-3">
        <label style="font-size: 14px;">Select Corporate Branch </label>
        <multiselect
          v-model="branchID"
          :options="branches"
          :show-labels="false"
          label="corpBranchName"
          track-by="corpBranchID"
          @input="onchangeBranch()"
        ></multiselect>
      </div>
      <div class="col-md-3 mb-3">
        <label
          class="form-label"
          for="formrow-endTime-input"
          style="font-size: 14px;"
          >Select Restaurant Branch</label
        >
        <multiselect
          v-model="restBranch"
          :options="cafeteriaArr"
          :show-labels="false"
          label="restaurantName"
          track-by="restBranchID"
          @input="onchangeRestBranch()"
        ></multiselect>
      </div>
      <div class="col-sm-2 col-md-3">
        <label
          class="form-label"
          for="formrow-date-input"
          style="font-size: 14px;"
          >Date Filter</label
        >
        <date-picker
          v-model="dateFilter"
          append-to-body
          confirm
          format="DD MMM Y"
          lang="en"
          range
        ></date-picker>
        <!-- @input="applyFilter();" -->
      </div>
      <div class="col-md-1">
        <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
          Apply Filter
        </button>
      </div>
      <div class="col-md-1">
        <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
          Clear Filter
        </button>
      </div>
      <!-- <div class="col-md-1 mt-3">
        <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 10px;" @click="clearFilter();"> Clear </button>
      </div> -->
    </div>

    <!-- <div class="row" style="margin-top: 48px;">
      <div class="col-md-6 col-xl-3">
          <div class="card">
              <div class="card-body">
                  <div>
                      <h4 class="mb-1 mt-1">
                        {{cardData.totalMealPlanOrders}}
                      </h4>
                      <p class="text-muted mb-0">Total Orders</p>
                  </div>
                
              </div>
          </div>
      </div>

      <div class="col-md-6 col-xl-3" v-for="(data) in cardData.mealsSeries" :key="data.id">
          <div class="card">
              <div class="card-body">
                  <div>
                      <h4 class="mb-1 mt-1">                          
                          {{data.count}}
                      </h4>
                      <p class="text-muted mb-0"> {{data.name}}</p>
                  </div>
                
              </div>
          </div>
      </div>

      <div class="col-md-6 col-xl-3">
          <div class="card">
              <div class="card-body">
                  <div>
                      <h4 class="mb-1 mt-1">
                        ₹
                        <span data-plugin="counterup">
                              <countTo :startVal="1000" :endVal="cardData.amount" :duration="2000"></countTo>
                          </span>
                      </h4>
                      <p class="text-muted mb-0"> Total Subsidy Payable</p>
                  </div>
                
              </div>
          </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div
                v-if="
                  login_type == 1 || login_type == 3
                "
                class="col-sm-12 col-md-4"
                style="margin-bottom:15px;"
              >
                <div class="btn-group" role="group">
                  <div class="btn-group" role="group">
                    <button
                      class="btn btn-themeBrown"
                      type="button"
                      @click="downloadSample()"
                    >
                      Excel
                    </button>
                  </div>
                  <!-- <button type="button" class="btn btn-themeBrown">Excel</button>  -->
                  <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- <div class="row">              
              <div class="col-sm-6 col-md-3">
                <label class="form-label" for="formrow-date-input">Date Filter</label>
                <date-picker v-model="dateFilter" format="DD MMM Y" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>           
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear Filter </button>
              </div>
            </div> -->

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :busy="isTableBusy"
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(transactionType)="data">
                  <span v-if="data.item.isExpired == 1" style="color:#F3766A;">
                    Expired
                  </span>
                  <span
                    v-else-if="data.item.transactionType == 1"
                    style="color:green;"
                  >
                    Credit
                  </span>
                  <span
                    v-else-if="data.item.transactionType == 2"
                    style="color:#A4827D;"
                  >
                    Debit
                  </span>
                </template>
                <template v-slot:cell(subsidy)="data">
                  <span v-if="data.item.subsidy">{{ data.item.subsidy }} </span>
                </template>
                <template v-slot:cell(quantity)="data">
                  <span v-if="data.item.quantity"
                    >{{ data.item.quantity }}
                  </span>
                </template>
                <template v-slot:cell(gstAmount)="data">
                  <span v-if="data.item.gstAmount"
                    >{{ data.item.gstAmount }}
                  </span>
                </template>
                <template v-slot:cell(subsidyPayable)="data">
                  <span v-if="data.item.subsidyPayable"
                    >{{ data.item.subsidyPayable }}
                  </span>
                </template>
                <template v-slot:cell(orderID)="data">
                  <div v-if="login_type == 4">
                    <span v-if="data.item.orderID">{{
                      data.item.orderID
                    }}</span>
                    <span v-else> - </span>
                  </div>
                  <div v-else>
                    <span
                      v-if="data.item.orderID"
                      style="cursor:pointer; color: rgb(243, 118, 106);"
                      @click="viewOrderDetails(data.item.id)"
                      >{{ data.item.orderID }}</span
                    >
                    <span v-else> - </span>
                  </div>
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderStatusArr="this.orderStatusArr"
      :orderedItemArr="this.orderedItemArr"
    >
    </OrderReciept>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Meal Plan Statistics",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    // countTo,
    DatePicker,
    Multiselect,
    OrderReciept
  },
  data() {
    return {
      login_type:0,
      isTableBusy: false,
      tableData: [],
      cardData: [],
      templateArr: [],
      restBranchArr: [],
      restBranchID: "",
      branches: [],
      branchID: "",
      cafeteriaArr: [],
      restBranch: "",
      countryArr: [],
      country: "",
      orderDetails: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      dateFilter: [],
      corporateID: "",
      corporateArr: [],
      title: "Meal Plan Statistics",
      items: [
        {
          text: "View"
        },
        {
          text: "Meal Plan Statistics",
          active: true
        }
      ],
      corpBranchID: "",
      cityArr: [],
      city: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      excelFields: {},
      fields: [
        {
          key: "employeeName",
          label: "Customer Name",
          sortable: true
        },
        {
          key: "employeeID",
          label: "Customer ID",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "mealPlan",
          sortable: true
        },
        {
          key: "dishName",
          sortable: true
        },
        {
          key: "orderID",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "restaurant",
          sortable: true
        },
        //  {
        //   key:"orderStatus",
        //   sortable:true,
        // },
        {
          key: "subsidy",
          label: "Subsidy (per head)",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "quantity",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "gstAmount",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "subsidyPayable",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "serviceDate",
          sortable: true
        },
        {
          key: "orderStatus",
          sortable: true
        }
      ]
    };
  },
  created() {
    this.corpBranchID =
        this.$storageData.login_type == 3 ||
        (this.$storageData.profile.empTypeID == 5 &&
            this.$route.params.type != "filtered")
            ? this.brandLogin()
            : this.getStats();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },

  mounted() {
    this.login_type = this.$storageData.login_type;
    // Set the initial number of items
    this.totalRows = this.items.length;

    if (this.$route.params.type == "filtered") {
      if (this.$route.params.date) {
        sessionStorage.setItem("date", this.$route.params.date);
      }
      if (this.$route.params.corpID) {
        sessionStorage.setItem("corpID", this.$route.params.corpID);
      }
      if (this.$route.params.corpBranchID) {
        sessionStorage.setItem("corpBranchID", this.$route.params.corpBranchID);
      }
      if (this.$route.params.restBranchID) {
        sessionStorage.setItem("restBranchID", this.$route.params.restBranchID);
      }
      if (this.$route.params.corpMealID) {
        sessionStorage.setItem("corpMealID", this.$route.params.corpMealID);
      }
      this.filteredMealPlanData();
      this.getCorporateList();

      if (
          sessionStorage.getItem("corpID") != "" &&
          sessionStorage.getItem("corpID") != undefined
      ) {
        this.getCorporateByCorpID();
      }
    }

    // this.readMealplanStats();
  },

  methods: {
    downloadSample() {
      this.isTableBusy = true;
      this.axios
          .post(this.$loggedRole+"/v2/download-meal-plan-statistics", {
            corpBranchID: this.corpBranchID,
            transactionType: this.typeID ? this.typeID.typeID : 0,
            dateFilter: this.dateFilter,
            restBranchID: this.restBranch.restBranchID,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID
          })
          .then(response => {
            this.isTableBusy = false;
            this.axios
                .post(
                    "exportExcel",
                    {
                      exportData: response.data.data,
                      page: "MealPlanStatistics"
                    },
                    {
                      responseType: "blob"
                    }
                )
                .then(response => {
                  const url = URL.createObjectURL(
                      new Blob([response.data], {
                        type: "application/vnd.ms-excel"
                      })
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Meal Plan Statistics Report.xlsx");
                  document.body.appendChild(link);
                  link.click();
                });
          });
    },
    getCorporateList() {
      this.corporateArr = [];
      this.corporateID = "";
      this.axios
          .post(this.$loggedRole+"/getCorporateLists", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            country: sessionStorage.getItem("mp_Country"),
            city: sessionStorage.getItem("mp_City")
          })
          .then(response => {
            this.corporateArr = response.data.data;
          });
    },
    filteredMealPlanData() {
      this.isTableBusy = true;

      this.axios
          .post(this.$loggedRole+"/getMealPlanStatistics", {
            corpBranchID: sessionStorage.getItem("corpBranchID"),
            transactionType: this.typeID ? this.typeID.typeID : 0,
            dateFilter: this.dateFilter,
            restBranchID: sessionStorage.getItem("restBranchID"),
            corpMealID: sessionStorage.getItem("corpMealID"),
            orderDate: sessionStorage.getItem("date"),
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID
          })
          .then(response => {
            this.isTableBusy = false;
            this.tableData = response.data.cardData.datatableData;
            this.cardData = response.data.cardData;
          });
    },

    brandLogin() {
      this.getCorporateCountry();
    },

    getCorporateCountry() {
      this.axios
          .post(this.$loggedRole+"/getCorporateCountry", {
            corporateID: this.$storageData.profile.corporateID
          })
          .then(response => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getCorporateCities();
          });
    },

    getCorporateCities() {
      var corpBranchID =
          this.$storageData.profile.empTypeID == 6 && this.$storageData.profile.corpBranchID != ""
              ? this.$storageData.profile.corpBranchID
              : 0;
      this.axios
          .post(this.$loggedRole+"/getCorporateCities", {
            corporateID: this.$storageData.profile.corporateID,
            country: this.country ? this.country.country : "",
            corpBranchID: corpBranchID
          })
          .then(response => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();
          });
    },

    getCorporateByCorpID() {
      this.axios
          .post(this.$loggedRole+"/getCorporateByCorpID", {
            corporateID: sessionStorage.getItem("mp_CorporateID")
                ? sessionStorage.getItem("mp_CorporateID")
                : this.$storageData.profile.corporateID
          })
          .then(response => {
            this.corporateID = response.data.data;
            this.getCorporateBranchByCorpID();
          });
    },

    getCorporateBranchByCorpID() {
      this.axios
          .post(this.$loggedRole+"/getCorporateBranchByCorpID", {
            corporateID:
                sessionStorage.getItem("mp_CorporateID") &&
                sessionStorage.getItem("mp_CorporateID") != "undefined"
                    ? sessionStorage.getItem("mp_CorporateID")
                    : this.$storageData.profile.corporateID,
            city:
                sessionStorage.getItem("mp_City") &&
                sessionStorage.getItem("mp_City") != "undefined"
                    ? sessionStorage.getItem("mp_City")
                    : this.city.city
          })
          .then(response => {
            this.branches = response.data.data;
            this.branchID = this.branches[0];
            this.corpBranchID = this.branchID.corpBranchID;
            // this.readMealplanStats();
            this.getCorporateCafeteria(this.corpBranchID);
          });
    },
    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
      this.getCorporateCafeteria(this.corpBranchID);
      //this.readMealplanStats();
    },

    onchangeRestBranch() {
      // this.restBranch = this.restBranch.restBranchID;
      this.corpBranchID =
          this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5
              ? this.branchID.corpBranchID
              : this.$storageData.profile.corpBranchID;
      // this.readMealplanStats();
    },

    getStats() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.getCorporateCafeteria(this.corpBranchID);
      //this.readMealplanStats();
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readMealplanStats() {
      this.isTableBusy = true;

      this.axios
          .post(this.$loggedRole+"/getMealPlanStatistics", {
            corpBranchID: this.corpBranchID,
            transactionType: this.typeID ? this.typeID.typeID : 0,
            dateFilter: this.dateFilter,
            restBranchID: this.restBranch.restBranchID,
            // 'orderDate':sessionStorage.getItem('date'),
            // 'corpMealID':sessionStorage.getItem('corpMealID'),
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID
          })
          .then(response => {
            this.isTableBusy = false;
            this.tableData = response.data.cardData.datatableData;
            this.cardData = response.data.cardData;
          });
    },

    applyFilter() {
      this.corpBranchID =
          this.$storageData.login_type == 1 ||
          this.$storageData.login_type == 3 ||
          this.$storageData.profile.empTypeID == 5
              ? this.branchID.corpBranchID
              : this.$storageData.profile.corpBranchID;
      this.readMealplanStats();
    },

    clearFilter() {
      this.typeID = 0;
      this.dateFilter = [];
      this.restBranch = "";
      // this.readMealplanStats();
    },

    getCorporateCafeteria(corpBranchID) {
      this.axios
          .post(this.$loggedRole+"/getCorporateCafeteria", { corpBranchID: corpBranchID })
          .then(result => {
            this.cafeteriaArr = result.data.data;
            this.restBranch = this.cafeteriaArr ? this.cafeteriaArr[0] : "";
            this.restBranchID = this.restBranch.restBranchID;
            //alert(this.restBranchID);
            // this.readMealplanStats();
            // if(this.$route.params.type=="filtered")
            // {
            //   this.filteredMealPlanData();
            // }
            // else
            // {
            //   this.readMealplanStats();
            // }
          });
    },

    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
          .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
          .then(result => {
            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
            // console.log(result.data.data.orderedItemArr);
            this.orderedItemArr = result.data.data.orderedItemArr;
            // this.orderItemAddonArr = result.data.orderItemAddonArr;
          });
    }
  },
  middleware: "authentication"
};
</script>
<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>
